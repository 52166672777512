import '../styles/pages/app.scss';
import * as React from "react"
import {HeadFC, navigate, Link} from "gatsby"
import {useEffect, useState} from "react";
import {
    BigLogoImage,
    LoadingGif,
    payScreen,
    feelGplay,
    feelApay
} from "../images";

// import useAnalytics from "../hooks/useAnalytics";

const payPage = () => {
    const [isLoading, setLoading] = useState(true);
    // const {initAnalytics, saveEmail} = useAnalytics();

    useEffect(() => {
        // initAnalytics();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    return (
        <main>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }

            <section className="payPage">
                <header className="payPageHeader">
                    <div className="payPageHeaderInside">
                        <img src={BigLogoImage} alt="" className="payPageLogo" />
                        <Link to="https://feeeeeeeel.com/click?o=8&a=3&sub_id1=newlend" className="payPageStarted">
                            <span className="payPageStartedInside">
                                Get started
                            </span>
                        </Link>
                    </div>
                    
                </header>
                <div className="payPageBox">
                    <h2 className="payPageTitle">
                        Start <br />
                        <span>Learning on the go</span><br />
                        For free
                    </h2>
                    <img src={payScreen} alt="" className="payPageMainimg" />
                    <div className="payPageSoc">
                        <Link to="https://feeeeeeeel.com/click?o=8&a=3&sub_id1=newlend">
                            <img src={feelGplay} alt="" />
                        </Link>
                        <Link to="https://feeeeeeeel.com/click?o=8&a=3&sub_id1=newlend">
                            <img src={feelApay} alt="" />
                        </Link>
                    </div>
                </div>
            </section>
            
        </main>
    )
}

export default payPage

export const Head: HeadFC = () => <title>AEER Platform</title>
